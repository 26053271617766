import type { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
// @ts-expect-error: image import
import previewImage from '../images/preview.png';
import { Body } from './Body';
import { TopNav } from './TopNav';

// inlined
import './fonts.css';

const Main = styled.main`
  max-width: 960px;
  margin: 0 auto;
  background: white;
  padding: 1rem 2rem;
  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
  }
`;

type PageFrameProps = {
  children: React.ReactNode;
  description: string | undefined;
  title: string | undefined;
};

export const PageFrame: FC<PageFrameProps> = ({
  children,
  description,
  title,
}) => {
  return (
    <Body>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        meta={[
          { content: description, name: 'description' },
          {
            content: 'initial-scale=1, viewport-fit=cover',
            name: 'viewport',
          },
          { content: title, name: 'og:title' },
          { content: 'website', name: 'og:type' },
          {
            content: `https://risto.io${previewImage as string}`,
            name: 'og:image',
          },
          { content: description, name: 'og:description' },
          { content: 'https://risto.io', name: 'og:url' },
        ]}
        title={title}
      />
      <Main>
        <TopNav />
        {children}
      </Main>
    </Body>
  );
};
PageFrame.displayName = 'PageFrame';
