import { Link } from 'gatsby';
import type { FC } from 'react';
import styled from 'styled-components';
import { Colors } from '../utils/Colors';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid ${Colors.Border};
`;

const LinkList = styled.ul`
  margin-left: 0;
  margin-right: 0;
  list-style-type: none;
  li {
    display: inline;
    &:not(:last-child) {
      margin-right: 1rem;

      @media (max-width: 768px) {
        margin-right: 0.5rem;
      }
    }
  }
`;

export const TopNav: FC = () => {
  return (
    <Header>
      <h1>
        <Link to="/">{'//rk'}</Link>
      </h1>
      <LinkList>
        <li>
          <Link to="/projects">projects</Link>
        </li>
        <li>
          <Link to="/contact">contact</Link>
        </li>
      </LinkList>
    </Header>
  );
};
TopNav.displayName = 'TopNav';
