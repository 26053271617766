import styled from 'styled-components';
import { Colors } from '../utils/Colors';

// argyle
export const Body = styled.div`
  min-height: 100vh;
  padding: 3rem;
  @media (max-width: 768px) {
    padding: 1rem;
  }

  background-color: ${Colors.Diamond};
  background-image: repeating-linear-gradient(
      120deg,
      ${Colors.StripeColor},
      ${Colors.StripeColor} 1px,
      transparent 1px,
      transparent 60px
    ),
    repeating-linear-gradient(
      60deg,
      ${Colors.StripeColor},
      ${Colors.StripeColor} 1px,
      transparent 1px,
      transparent 60px
    ),
    linear-gradient(
      60deg,
      ${Colors.Diamond2} 25%,
      transparent 25%,
      transparent 75%,
      ${Colors.Diamond2} 75%,
      ${Colors.Diamond2}
    ),
    linear-gradient(
      120deg,
      ${Colors.Diamond2} 25%,
      transparent 25%,
      transparent 75%,
      ${Colors.Diamond2} 75%,
      ${Colors.Diamond2}
    );
  background-size: 70px 120px;

  a {
    color: ${Colors.Link};
  }
`;
